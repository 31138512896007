.interviewContainer{
    width:900px;
    margin:auto;
    padding:10px 0;
    background-color: white;
    color:black;
    padding-bottom: 240px;
}
body{
  background-color: white;
}
.hint{
    width:35%;
    padding:10px;
    border-radius: 20px;
    background-color: white;
    color:red;
    text-align:center;
    height:fit-content;
    border:1px solid red;
}
/* #page-container {
    position: relative;
    min-height: 100vh;
  } */
.founderVideo{
    position: relative;
    padding-bottom: 56.25%;
    /* height: 0; */
    width:70%;
    margin:auto;
    overflow: hidden,
}
.logo{
    margin: 0 auto;
    text-align:center;
}

.logoPic{
    width:250px;
}

.alignLeft{
    text-align:left;
}
.smaller{
    font-size: 16px;
}

.question{
    width:32px;
    height:32px;
    /* vertical-align: super; */
    margin-top:20px;
}
.centeredHeader{
    text-align:center;
}
.questionContainer{
    background-color: #5F9DF7;
    color:black;
    padding:10px 30px 30px 30px;
    /* width:50%; */
    margin:auto;
    border-radius: 20px;
    margin-top:1%;
    
}
.headerContainer{
    display:flex;
    justify-content: space-between;
}
.payNowButton{
    text-align:right;
    margin-top:10px;
}
input{
    padding:10px;
    border:none;
}

.nextButton{
    text-align:center;
    margin-top:3%;
    
}

.questionClosed{
    display:flex;
    justify-content: space-between;
    background-color: #1746A2;
    color:white;
    padding:1px 30px 1px 30px;
    /* width:50%; */
    margin:auto;
    border-radius: 20px;
    margin-top:1%;
}

.editButton{
    height:30px;
    width:30px;
    margin-top:15px;
}

.invisible{
    display:none;
}

.visible{
    display:contents;
}



input[type=radio] {
    padding: 1em;
    -webkit-appearance: none;
    /* outline: 0.1em solid black; */
    background-color:white;
    outline-offset: 0.1em;
    margin-right:1em;
  }
  
  input[type=radio]:checked {
    /* display: inline-block;
    background-color: #000; */
    background: url(./images/tick-icon.png);
	background-repeat: no-repeat;
	background-position:center center;
	background-size:20px 20px;
    background-color: white;
  }
input[type=checkbox] {
    padding: 1em;
    -webkit-appearance: none;
    /* outline: 0.1em solid black; */
    background-color:white;
    outline-offset: 0.1em;
    margin-right:1em;
  }
  
  input[type=checkbox]:checked {
    /* display: inline-block;
    background-color: #000; */
    background: url(./images/tick-icon.png);
	background-repeat: no-repeat;
	background-position:center center;
	background-size:20px 20px;
    background-color: white;
  }
  .stacked{
    display:flex;
    flex-direction: column;
  }

  label{
    margin-bottom:.25em;
    margin-top:.5em;
  }

  .toc {
    padding:60px;
    padding-bottom: 180px;
  }

  h3{
    font-weight: bold;
    color:black;
  }

.buttonContainer{
    text-align:right;
}

.buttonSpacing{
    margin-right:10px;
}
.thumbnail{
    width:35%;
    height: 35%;
}
.container{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
}
.formContainer{
    display:flex;
    flex-direction: row;
    justify-content:space-between;
}
.columnContainer{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
}
.columnContainer{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    width:50%;
}
.error{
    color:white;
}
/* .row{
    display:flex;
    flex-direction: column;
} */

.bigText{
    font-size:x-large;
}
.row{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    margin:1% auto;
    width:47%;
}
.dashboardRow{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin:1% auto;
    width:60%;
}
.wider{
    padding:5px 50px;
}
.white{
    color:white;
    text-decoration: none;
}
.shaded{
    
    background-color: lightgray;
    border-collapse: collapse;
}
td{
    padding: 5px;
    border: 1px solid gray;
}
 .bold{
    font-weight: bold;
 }
a {
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    word-break: break-all; 
}
 @media (max-width: 900px) {
  .interviewContainer{
    width:100%;
}
.questionContainer{
   
    width:80%;
   
    
}
.thumbnail{
    width:100%;
    height: 100%;
    margin-top:1em;
    margin-bottom:1em;
}
.questionClosed{
    width:80%;
    
}
.toc {
    padding:10px;
    padding-bottom: 180px;
  }
  .formContainer{
    flex-direction: column;
  }
}