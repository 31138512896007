
.homeContainer{
    
    background-color: #1746A2;
    /* padding-bottom: 240px; */
    /* box-sizing: border-box; */
}
.mainContent{
    width:1200px;
    padding:20px;
    margin:auto;
}
.siteHeaderContainer{
    /* width:1000px; */
    margin:auto;
    /* padding:20px; */
    background-color: #1746A2;
    
}
.navigation{
    text-align:right;
    cursor: pointer;
    margin-right: 32px;
}

.header{
    /* margin-left:5%; */
    text-align: right;
}

.logo{
    text-align:right;
    
}

.logoPic{
    width:200px;
    filter: drop-shadow(5px 5px 5px #0a335d);
}

.loginButton{
   
    margin-top:1%;
    padding-right:5%;
}

.heroContent{
    display:flex;
    margin-top:2%;
    /* align-items:flex-start; */
}

.heroPic{
    width:55%;
    /* border-radius: 50%; */
    background-size: cover;
}

.heroRow{
    padding:2%;
    display:flex;
    flex-direction: column;
    margin-top:0;
    /* align-items:flex-start; */
}
.heroVideo{
    width:55%;
    margin:0 auto;
    margin-top:0;
}
.whatYouGet{
    width:45%;
    margin: 0% 2%;
}

.cta{
    text-align:center !important;

}
.cta2{
    text-align:center;

}
.navItems{
    list-style-type:none;
    margin-bottom:1em;
    color:black;
}
.navItems:hover {
    cursor:pointer;
    font-weight:bold;
}
p.home,li.home, h1.home, h2.home{
    color:white;
}
p.home, li.home{
    font-size:20px;
    line-height:125%;
}
@media (max-width: 1240px) {
 
.logo{
    text-align: center;
}
h1{
    text-align:center;
}
.cta2{
    text-align:center;
}
.headerContainer{
   
    padding:0;
  
}

.logoPic{
    width:100px;
text-align:center;
}
.header{
    margin-left:0;
    display:flex;
    flex-direction: column;
    
}
.navigation {
    margin-right:0;
    text-align:center;
}
.loginButton{
    width:fit-content;
    text-align:center;
    margin:auto;
    padding-right:0;
}
.heroContent{
    flex-direction: column;
    /* width:95%; */
    /* text-align:center;
    margin:auto; */
}
.whatYouGet{
    width:100%;
    margin: 3% 0 5% 0;
}
.heroRow{
    margin-left:0;
    
}
.heroVideo{
    width:100%;
    
}
.heroPic{
    width:100%;
    margin:auto;
}
h1,h2{
    font-size:24px;
    text-align:center
}
p, li{
    font-size:18px;
    line-height:125%;
}
.mainContent{
    width:100%;
    margin:0;
    padding:0;
}
 .homeContainer{
    width:100%;
    padding:20px;
    
    margin:0;
    box-sizing: border-box; 
    
}
}