  
  .sp-modal__wrapper{
    position: relative;
    margin-left:30%;
    margin-right:30%;
    border-radius: 20px;
    background: #fff;
    overflow: hidden;
  }

  .sp-modal {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    /* width: 100%;
    height: 100%; */
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.sp-modal__wrapper.sp-modal.modal-fade-out {
  opacity: 1;
  transition: opacity 1s ease-out !important; /* High specificity */
}

.sp-modal__wrapper.sp-modal.modal-hidden {
  opacity: 0 !important; /* Ensure this takes effect */
}
@media (max-width: 600px) {
   .sp-modal__wrapper{
    margin-left:5%;
    margin-right:5%;
   max-width:90%;
  }
}
