
.footerBlock{
width:100%;
background-color: #1746A2;
padding:2% 0;
margin-top:2%;
}
.footer{
  display:flex;
  justify-content:space-between;
  width:80%;
  margin:0 auto 0 auto;
}
.footerContainer{
    
    color:white;
    display:flex;
    
    width:40%;
    justify-content: space-around;
    text-align:left;
    /* position: fixed;
  bottom: 0; */
  /* width: 100%; */
  
}
.footerContainerRight{
    
    color:white;
    display:flex;
    
    width:50%;
    justify-content: space-around;
   
  
}

.copyright{
    padding-right:140px;
    
}
@media (max-width: 900px) {
  .footerContainer {
    display: block;
    padding: 10px 20px;
    text-align:center;
    margin: 0 auto; 
    width: 100%; 
    max-width:100%;
    box-sizing: border-box; 
  }
  .footerContainerRight {
    display: block;
    padding: 10px 20px;
    text-align:center;
    margin: 0 auto; 
    width: 100%; 
    max-width:100%;
    box-sizing: border-box; 
  }
  .footer{
  display:flex;
  flex-direction: column;
  width:100%;
  margin:1% auto 2% auto;
  text-align:center;
  background-color: #1746A2;
}
}
