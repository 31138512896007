@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap');

p, li {
  font-family: 'Archivo', sans-serif;
}

h1, h2, h3 {
  font-family: 'Archivo', sans-serif;
  font-weight:normal;
}

h1{
  font-size:48px;
  margin-bottom:3%;
}

h2{
  font-size:36px;
}

h3{
  font-size:24px;
}

p, li, label, input{
  font-size:22px;
}
input{
  font-size:20px;
  font-weight:600;
}
button{
  font-size: 20px;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #fda753;
  border:none;
  cursor:pointer;
}